import React from 'react'
import { Link } from 'gatsby'
import { graphql } from 'gatsby'
import { dateFormat } from '../helpers/formats'
import Layout from '../components/layout'
import Pill from '../components/pill'
import PublicationCard from '../components/publicationCard'
import styles from '../styles/digest.module.css'
import BackArrow from '../assets/back-arrow.svg'

const Digest = ({data}) => {

    const { airtableDigests:digest } = data
	const {Title:title, Date_updated:dateUpdated, News:publications, Topics:topics} = digest.data

	const sortedPub = publications?.sort((a,b) =>{
		let bdate = new Date( b.data.Date).getTime()
		let adate = new Date(a.data.Date).getTime()
		return bdate - adate  }
	)

	return (
        <Layout>
            <Link to='../'><div className={styles.back}><BackArrow/><span>Back to Weather report</span></div></Link>
            <div className={styles.digest}>
				<div className={styles.timestamp}>{dateFormat(new Date(dateUpdated))}</div>
                <h2>{title}</h2>
				<div className={styles.topics}>
                    {
                        topics?.sort((a, b) => a.localeCompare(b)).map((topic, i) => (
                            <Pill key={i} link={`/weatherReport?topic=${topic}`} label={topic}/>
                        ))
                    }
                </div>
				<h3>Publications</h3>
				<div className={styles.publications}>
					{

						sortedPub?.map((publications, i) => (
							<PublicationCard key={i} title={publications.data.Title} author={publications.data.Author} date={publications.data.Date} abstract={publications.data.Abstract} sourceType={publications.data.Source_type} source={publications.data.Source} journal={publications.data.Journal}/>

						))
					}
				</div>

            </div>
        </Layout>
    )
}

export const query = graphql`
    query ($slug: String!) {
        airtableDigests(data: {Slug: {eq: $slug}}) {
            data {
				Date_updated
				News {
					data {
						Title
						Author
						Abstract
						Source_type
						Source
						Date
						Journal
						Last_Modified
						Journal
						Last_Modified
						Journal
					}
				}
				Title
				Topics
			}
        }
    }
`

export default Digest
